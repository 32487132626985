import React, { useState, useEffect, useRef } from 'react'
import { useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'
import Form from 'react-bootstrap/Form'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import GenericModal from '../../../components/component-items/modal'
import { TableCell } from '../../../components/component-items/datatable'
import InputGroup from 'react-bootstrap/InputGroup'
import { FilterListItem, DateRangeStyled } from './styles'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar, DateRangePicker } from 'react-date-range'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons'

import { CommissionReportModalProps, CompanyModalProps } from './types'

const CompanyModal = ({ open, setOpen, users, id, sales_rep, commission_start_date }: CompanyModalProps) => {
  const node = useRef<HTMLFormElement | null>(null)
  const {
    state: { csrf },
  } = globalState()

  const [date, setDate] = useState(new Date())
  const [user, setUser] = useState('')

  const [validated, setValidated] = useState(false)

  useEffect(() => {
    let currentUser = users.find((u: any) => u.id === sales_rep)
    if (currentUser) {
      setUser(currentUser.id)
    }
    if (commission_start_date) {
      let currentDate = new Date(commission_start_date)
      currentDate.setDate(currentDate.getDate() + 1)
      setDate(currentDate)
    }
  }, [open])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    const updatedDate = date.toLocaleDateString('en-US').replace(/\//g, '-')
    usePost('/api/billing/commissions', { ...{ id, user }, date: updatedDate }, csrf)
  }

  return (
    <GenericModal
      heading={'Company Commission Structure'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
          Update
        </GlobalStyles.Button>
      }
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <div style={{ margin: '1em' }}>
          <FilterListItem>
            <Form.Label style={{ width: '100%', textAlign: 'center' }}>Commission Start Date</Form.Label>
            <DateRangeStyled>
              <Calendar
                onChange={(e) => setDate(e)}
                date={date}
                showMonthAndYearPickers={false}
                className="hide-in-percy"
              />
            </DateRangeStyled>
            <Form.Label>Sales Rep</Form.Label>
            <Form.Select name="type" value={user} onChange={(e) => setUser(e.target.value)}>
              <option value="">None</option>
              {users.map(({ id, first_name, last_name }: any) => (
                <option value={id} key={id}>
                  {first_name} {last_name}
                </option>
              ))}
            </Form.Select>
          </FilterListItem>
        </div>
      </Form>
    </GenericModal>
  )
}

export const CommissionReportModal = ({ open, setOpen, users }: CommissionReportModalProps) => {
  const node = useRef<HTMLFormElement | null>(null)
  const {
    state: { csrf },
  } = globalState()
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [validated, setValidated] = useState(false)
  const [user, setUser] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    var startDate = state[0].startDate.toLocaleDateString('en-US')
    var endDate = state[0].endDate.toLocaleDateString('en-US')
    setStartDate(startDate)
    setEndDate(endDate)
  }, [state])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    const start = startDate.replace(/\//g, '-')
    const end = endDate.replace(/\//g, '-')

    usePost('/api/billing/commissions', { ...{ start, end, user } }, csrf)
  }

  return (
    <GenericModal
      heading={'Commission Report'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
          Export Commissions
        </GlobalStyles.Button>
      }
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit}>
        <div style={{ margin: '1em' }}>
          <FilterListItem>
            <Form.Label>Sales Rep</Form.Label>
            <Form.Select name="type" required value={user} onChange={(e) => setUser(e.target.value)}>
              <option value="" disabled hidden>
                -- SELECT --
              </option>
              {users.map(({ id, first_name, last_name }) => (
                <option value={id} key={id}>
                  {first_name} {last_name}
                </option>
              ))}
            </Form.Select>
            <Form.Label style={{ marginTop: '1em' }}>Date Range</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                value={startDate + ' - ' + endDate}
                onChange={(e) => null}
                onFocus={() => {
                  const startDate = state[0].startDate.toLocaleDateString('en-US')
                  setStartDate(startDate)
                  const endDate = state[0].endDate.toLocaleDateString('en-US')
                  setEndDate(endDate)
                }}
                placeholder="Choose A Date"
                aria-describedby="inputGroupAppend"
                required
              />
              <InputGroup.Text id="inputGroupAppend">
                <FontAwesomeIcon icon={faCalendarWeek} />
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
            </InputGroup>
          </FilterListItem>
          {/* Set defaults here: https://pastebin.com/NdYs3hda */}
          <DateRangeStyled>
            <DateRangePicker
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              months={1}
              minDate={new Date(2018, 1, 1)}
              maxDate={new Date()}
              editableDateInputs={true}
              ranges={state}
              direction="horizontal"
              className="hide-in-percy"
            />
          </DateRangeStyled>
        </div>
      </Form>
    </GenericModal>
  )
}

export const CommissionsReport = () => {
  const [exportModal, setExportModal] = useState(false)

  const [editModal, setEditModal] = useState(false)
  const [company, setCompany] = useState({})

  const res: any = useFetch('/api/billing/commissions', {})
  const documents = res.response ? res.response.documents : []
  const users = res.response ? res.response.users : []
  const companies = res.response ? res.response.companies : []

  return (
    <>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Commissions Reporting</h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            <GlobalStyles.Button onClick={() => setExportModal(true)}>Create Report</GlobalStyles.Button>
            <CommissionReportModal {...{ users }} open={exportModal} setOpen={setExportModal} />
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 500, padding: '0 1em' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <th>
                  <span className="text">Created</span>
                </th>
                <th>
                  <span className="text">Sales Rep</span>
                </th>
                <th>
                  <span className="text">Title</span>
                </th>
                <th>
                  <span className="text center">Commission</span>
                </th>
                <th>
                  <span className="text center">Download</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {documents.map(({ user, title, description, amount, url, created }: any, id: number) => (
                <GlobalStyles.TableRow key={id}>
                  <TableCell>{created}</TableCell>
                  <TableCell input={user} />
                  <TableCell input={title}>
                    <div>{description}</div>
                  </TableCell>
                  <TableCell>
                    <StatusBoxStyled className="purple" style={{ width: 100, margin: 'auto', textAlign: 'center' }}>
                      {amount}
                    </StatusBoxStyled>
                  </TableCell>
                  <TableCell center>
                    {url ? (
                      <a href={url}>
                        <GlobalStyles.Button style={{ color: 'white' }}>Download</GlobalStyles.Button>
                      </a>
                    ) : (
                      <StatusBoxStyled className="purple" style={{ width: 100, margin: 'auto' }}>
                        Downloading
                      </StatusBoxStyled>
                    )}
                  </TableCell>
                </GlobalStyles.TableRow>
              ))}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
      </GlobalStyles.FullPageCard>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>Commissions Structure</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 500, padding: '0 1em' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <th>
                  <span className="text">Company</span>
                </th>
                <th>
                  <span className="text center">Sales Rep</span>
                </th>
                <th>
                  <span className="text center">Start Date</span>
                </th>
                <th>
                  <span className="text center">Tier</span>
                </th>
                <th>
                  <span className="text center">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {companies.map(
                ({ id, name, sales_rep_name, commission_start_date_formatted, tier, tier_percent }: any) => (
                  <GlobalStyles.TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell center input={sales_rep_name} />
                    <TableCell center>{commission_start_date_formatted}</TableCell>
                    <TableCell center>
                      <StatusBoxStyled
                        className={tier === 1 ? 'green' : tier === 2 ? 'blue' : tier === 3 ? 'silver' : 'grey'}
                        style={{ width: 100, margin: 'auto', textAlign: 'center' }}
                      >
                        {tier_percent}
                      </StatusBoxStyled>
                    </TableCell>
                    <TableCell center>
                      <div className="dropdown__container">
                        <button
                          onClick={() => {
                            setCompany(companies.find((c: any) => c.id === id))
                            setEditModal(true)
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </div>
                    </TableCell>
                  </GlobalStyles.TableRow>
                )
              )}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
        <CompanyModal open={editModal} setOpen={setEditModal} {...company} {...{ users }} />
      </GlobalStyles.FullPageCard>
    </>
  )
}
