import React from 'react'
import { useFetch } from '../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { TableCell } from '../../../components/component-items/datatable'
import { OrderStatusBox } from '../../../components/component-items/status-box'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt, faCreditCard, faSignalStream } from '@fortawesome/pro-duotone-svg-icons'
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons'

export const PaymentsModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const res: any = useFetch('/api/billing/payment-data/', {})

  const loaded = res.loaded
  const resp = res.response

  const payments = loaded ? resp.payments : []

  return (
    <GenericModal
      heading={'Payments'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div style={{ width: '100%', minHeight: 300 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Date</span>
              </th>
              <th>
                <span className="text">Payment Method</span>
              </th>
              <th>
                <span className="text center">Amount</span>
              </th>
              <th>
                <span className="text center">Receipt</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {payments.map((p: any, id: number) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell input={p.date} />
                <TableCell
                  input={
                    <div style={{ color: '#444444' }}>
                      <FontAwesomeIcon
                        icon={
                          p.card_type === 'MC'
                            ? faCcMastercard
                            : p.card_type === 'VI'
                              ? faCcVisa
                              : p.card_type === 'wire'
                                ? faSignalStream
                                : faCreditCard
                        }
                        style={{ fontSize: '1.5em', marginRight: 5 }}
                      />
                      {p.last_digits}
                      {p.card_type === 'wire' ? 'Wire' : ''}
                    </div>
                  }
                />
                <TableCell
                  center
                  input={<OrderStatusBox style={{ width: 100, margin: 'auto' }} status={'$' + p.amount} />}
                />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      {p.id ? (
                        <button>
                          <a
                            href={`/api/billing/receipt/${p.id}/`}
                            rel="noreferrer"
                            target="__blank"
                            style={{ fontSize: '1.5em' }}
                          >
                            <FontAwesomeIcon icon={faReceipt} />
                          </a>
                        </button>
                      ) : (
                        'N/A'
                      )}
                    </div>
                  }
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
        {!payments.length ? (
          <div style={{ textAlign: 'center', paddingTop: 80 }}>
            <div
              role="img"
              aria-label="sleeping"
              style={{ fontSize: '4em', display: 'flex', justifyContent: 'center' }}
            >
              🧾
            </div>
            No Payments made!
          </div>
        ) : null}
      </div>
    </GenericModal>
  )
}
