import React, { useState, useRef, useEffect } from 'react'
import { globalState } from '../../../store'
import { Link, useLocation } from 'react-router-dom'
import { useDebounce } from 'use-lodash-debounce'
import { useClick, useFetch } from '../../component-items/helpers'
import { deviceSize } from '../../component-items/device'
import windowDimensions from '../../component-items/window-dimensions'
import * as Sentry from '@sentry/browser'
import { Form } from 'react-bootstrap'
import { notify } from '../../../components/component-items/helpers'

// Components
import { Breadcrumbs } from 'react-breadcrumbs'
import { Styled } from './styles'
import GlobalStyles from '../../component-items/styles'
import Clock from 'react-live-clock'
import GenericModal from '../../component-items/modal'
import { useTheme } from 'styled-components'
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Options
import { Notifications } from './notifications'
import { Search, SearchResultItem } from './search'
import { UserBar } from './user'
import { QuickActions } from './quick-actions'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBuilding } from '@fortawesome/pro-light-svg-icons'
import {
  faSearch,
  faBell,
  faCubes,
  faShoppingBasket,
  faBox,
  faRampLoading,
  faCalendarAlt,
  faDoorOpen,
  faWrench,
  faSignalAlt3,
  faBars,
  faCogs,
  faSlidersH,
  faInfoSquare,
  faClipboardListCheck,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faBug,
  faUserHeadset,
  faPlus,
  faDollarSign,
  faFileInvoiceDollar,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons'

import { WarehouseProps } from './types'

const HeaderMenuItem = ({ title, children }: { title: string | React.ReactNode; children: React.ReactNode }) => {
  const [isOpen, setOpen] = useState(false)
  const node = useRef<HTMLLIElement>(null)
  useClick(node, setOpen)
  return (
    <li className="header-menu__item" aria-haspopup="true" ref={node}>
      <button className={'menu-button ' + (isOpen ? 'active' : '')} onClick={() => setOpen(!isOpen)}>
        <span>{title}</span>
        <i>
          <FontAwesomeIcon icon={faChevronDown} />
        </i>
      </button>
      {isOpen ? children : null}
    </li>
  )
}

const HeaderMenu = () => {
  const [isLoading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [list, setList] = useState([])
  const [userFeedbackModal, setUserFeedbackModal] = useState(false)
  const debouncedValue = useDebounce(value, 1000)

  const {
    state: { userIsWarehouse, multiWarehouse, warehouseActive, warehousesInactive, warehousesRemaining, actAs },
  } = globalState()

  const url = userIsWarehouse ? '/api/company/' : ''
  var { response, loaded, error, placeholder } = useFetch(url, {})
  var results = value ? list : response

  const title = actAs ? (
    <span>
      Acting As: <span className="acting-as">{actAs}</span>
    </span>
  ) : (
    'Act As'
  )

  useEffect(() => {
    if (debouncedValue) {
      const _companies = ((response || []) as any[])?.filter(function (c: any) {
        return c.name?.toLowerCase().match(debouncedValue?.toLowerCase())
      })
      // @ts-ignore
      setList(_companies || [])
    } else {
      setList([])
    }
    setLoading(false)
  }, [debouncedValue])

  return (
    <Styled.HeaderMenu>
      <ul className="header-menu__list">
        {userIsWarehouse || multiWarehouse ? (
          <HeaderMenuItem title={warehouseActive.name || 'All Warehouses'}>
            <Styled.MenuPopup>
              <ul className="menu-section">
                <li className="menu-section__item">
                  <h3>Warehouses</h3>
                  <ul className="menu-list">
                    {!userIsWarehouse && multiWarehouse && warehouseActive.name ? (
                      <li className="menu-list__item">
                        <a
                          href={userIsWarehouse ? '/api/warehouse/change_warehouse/' : '/api/company/change_warehouse/'}
                        >
                          <i>
                            <span></span>
                          </i>
                          <span>All Warehouses</span>
                        </a>
                      </li>
                    ) : null}
                    {warehousesInactive.map((w: WarehouseProps, id: number) => (
                      <li className="menu-list__item" key={id}>
                        <a
                          href={
                            (userIsWarehouse
                              ? '/api/warehouse/change_warehouse/?warehouse='
                              : '/api/company/change_warehouse/?warehouse=') + w.id
                          }
                        >
                          <i>
                            <span></span>
                          </i>
                          <span>{w.name}</span>
                        </a>
                      </li>
                    ))}
                    {userIsWarehouse &&
                      warehousesRemaining.map((w: any, id: number) => (
                        <li className="menu-list__item" key={id}>
                          <a
                            href={
                              (userIsWarehouse
                                ? '/api/warehouse/change_warehouse/?warehouse='
                                : '/api/company/change_warehouse/?warehouse=') + w.id
                            }
                          >
                            <i>
                              <span></span>
                            </i>
                            <span>
                              {w.name} <FontAwesomeIcon icon={faDoorOpen} style={{ color: '#900000' }} />
                            </span>
                          </a>
                        </li>
                      ))}
                  </ul>
                </li>
              </ul>
            </Styled.MenuPopup>
          </HeaderMenuItem>
        ) : null}
        {userIsWarehouse ? (
          <HeaderMenuItem title={title}>
            <Styled.ActAsPopup>
              <div className="dropdown__menu">
                <form method="get" onSubmit={(e) => e.preventDefault()}>
                  <div className={'input-area ' + (isLoading ? 'spinner' : '')}>
                    <div className="input-icon">
                      <i>
                        <FontAwesomeIcon icon={faSearch} />
                      </i>
                    </div>
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => {
                        setValue(e.target.value)
                        setLoading(true)
                      }}
                      value={value}
                    />
                    {!isLoading ? (
                      <div className="input-icon close" onClick={() => setValue('')}>
                        <i>
                          <FontAwesomeIcon icon={faTimes} />
                        </i>
                      </div>
                    ) : null}
                  </div>
                </form>
                <div className="search__result">
                  {actAs ? (
                    <SearchResultItem icon={faDoorOpen} url={'/api/act-as'} title={'Exit'} className="exit" />
                  ) : null}
                  {loaded ? (
                    results ? (
                      <>
                        <div className="search__category">Companies</div>
                        {results?.map((c: any, id: number) => (
                          <SearchResultItem
                            icon={faBuilding}
                            url={'/api/act-as?company=' + c.id}
                            image={c.logo.includes('placeholder') ? null : c.logo}
                            title={c.name}
                            desc={
                              c.multiWarehouse
                                ? c.activeWarehouses.map(({ name }: { name: string }) => name).join(', ')
                                : c.warehouse?.name
                            }
                            key={id}
                          />
                        ))}
                      </>
                    ) : (
                      <div className="search__message">
                        Sorry, Company Not Found &nbsp;
                        <span role="img" aria-label="shrug">
                          🤷
                        </span>
                      </div>
                    )
                  ) : (
                    <div>{placeholder}</div>
                  )}
                </div>
              </div>
            </Styled.ActAsPopup>
          </HeaderMenuItem>
        ) : null}
      </ul>
    </Styled.HeaderMenu>
  )
}

const HeaderItem = ({ children, width }: { children: React.ReactNode; width?: string }) => (
  <Styled.HeaderTopbarItem width={width}>
    <div className="header__topbar__container">{children}</div>
  </Styled.HeaderTopbarItem>
)

const HeaderMenuOptions = ({ node }: { node?: React.MutableRefObject<null> }) => {
  const {
    state: { userIsWarehouse },
  } = globalState()

  const support_url = userIsWarehouse ? process.env.REACT_APP_CONFLUENCE_WAREHOUSE_URL : 'https://help.shippingtree.co/'
  return (
    <div className="header__topbar">
      <HeaderItem>
        <Search />
      </HeaderItem>
      <HeaderItem>
        <Notifications />
      </HeaderItem>
      <HeaderItem>
        <Styled.HeaderButton onClick={() => window.open(support_url, '_blank')} aria-haspopup="true">
          <span className={'header__topbar-icon'}>
            <i>
              <FontAwesomeIcon icon={faWrench} />
            </i>
          </span>
        </Styled.HeaderButton>
      </HeaderItem>
      <HeaderItem>
        <Styled.HeaderButton
          onClick={() => window.open('http://status.shippingtree.co/', '_blank')}
          aria-haspopup="true"
        >
          <span className={'header__topbar-icon'}>
            <i>
              <FontAwesomeIcon icon={faSignalAlt3} />
            </i>
          </span>
        </Styled.HeaderButton>
      </HeaderItem>
      <HeaderItem>
        <QuickActions />
      </HeaderItem>
      <HeaderItem width={'100%'}>
        <UserBar />
      </HeaderItem>
    </div>
  )
}

export const Header = ({ node }: { node: any }) => {
  const location = useLocation()
  const {
    state: { actAs, navOpen },
    dispatch,
  } = globalState()
  const { width } = windowDimensions()
  const [openMobileSubNav, setMobileSubNav] = useState(false)
  const [openMobileSubHeader, setMobileSubHeader] = useState(false)

  const closeMenus = () => {
    if (width >= deviceSize.laptop) {
      return
    }
    dispatch({ type: 'navCloseSave' })
    setMobileSubNav(false)
    setMobileSubHeader(false)
  }

  const clickMenus = () => {
    if (width >= deviceSize.laptop) {
      return
    }
    dispatch({ type: navOpen ? 'navCloseSave' : 'navOpenSave' })
    setMobileSubNav(false)
    setMobileSubHeader(false)
  }

  useEffect(() => {
    // Close menus on load if mobile, but don't dispatch
    if (width >= deviceSize.laptop) {
      return
    }
    setMobileSubNav(false)
    setMobileSubHeader(false)
  }, [width, location])

  const reverse = true
  useClick(node, closeMenus, reverse)
  return (
    <>
      <Styled.MobileHeader className={actAs ? 'act-as__active' : ''} {...{ navOpen }}>
        <Styled.BrandLogo>
          <a href="/">
            <img alt="Logo" width="25px" src={'/api/static/assets/media/logos/ShippingTree_Logo.png'} />
            <span className="menu__link-text">ShippingTree</span>
          </a>
        </Styled.BrandLogo>
        <div className="header__topbar">
          <HeaderItem>
            <Styled.HeaderButton
              onClick={() => {
                setMobileSubHeader(false)
                setMobileSubNav(!openMobileSubNav)
              }}
            >
              <span className={'header__topbar-icon'} style={{ fontSize: '2.5em' }}>
                <i>
                  <FontAwesomeIcon icon={faSlidersH} />
                </i>
              </span>
            </Styled.HeaderButton>
          </HeaderItem>
          <HeaderItem>
            <Styled.HeaderButton
              onClick={() => {
                setMobileSubHeader(false)
                setMobileSubHeader(!openMobileSubHeader)
              }}
            >
              <span className={'header__topbar-icon'} style={{ fontSize: '2.5em' }}>
                <i>
                  <FontAwesomeIcon icon={faCogs} />
                </i>
              </span>
            </Styled.HeaderButton>
          </HeaderItem>
          <HeaderItem>
            <Styled.HeaderButton onClick={() => clickMenus()}>
              <span className={'header__topbar-icon'} style={{ fontSize: '2.5em' }}>
                <i>
                  <FontAwesomeIcon icon={faBars} />
                </i>
              </span>
            </Styled.HeaderButton>
          </HeaderItem>
        </div>
      </Styled.MobileHeader>
      {openMobileSubNav ? (
        <Styled.MobileSubHeader>
          <HeaderMenu />
        </Styled.MobileSubHeader>
      ) : null}
      {openMobileSubHeader ? (
        <Styled.MobileSubHeader>
          <div />
          <HeaderMenuOptions />
        </Styled.MobileSubHeader>
      ) : null}
      <Styled.Header className={actAs ? 'act-as__active' : ''} {...{ navOpen }}>
        <HeaderMenu />
        <HeaderMenuOptions />
      </Styled.Header>
    </>
  )
}

const SubNavItem = ({ url, icon, title, children }: { url: string; icon: any; title: string; children?: any }) => (
  <Styled.SubNavItem>
    <a href={url}>
      <i>
        <FontAwesomeIcon icon={icon} />
      </i>
      <span className="nav__link-text">{title}</span>
      {children}
    </a>
  </Styled.SubNavItem>
)

export const SubHeader = () => {
  const theme: any = useTheme()
  const [isOpen, setOpen] = useState(false)
  const node = useRef<HTMLSpanElement>(null)
  useClick(node, setOpen)
  const [userFeedbackModal, setUserFeedbackModal] = useState(false)
  const [feedbackDescription, setFeedbackDescription] = useState('')

  const pathname = window.location.pathname
  const is_dashboard = pathname === '/company/dashboard/' || pathname === '/warehouse/dashboard/'
  const {
    state: { userIsWarehouse, userFullName, userEmail },
    dispatch,
  } = globalState()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const eventId = Sentry.captureMessage('User Feedback')
    const userFeedback = {
      event_id: eventId,
      name: userFullName,
      email: userEmail,
      comments: feedbackDescription,
    }
    Sentry.captureUserFeedback(userFeedback)
    notify({ type: 'success', message: 'Your feedback has been submitted!' })
    setUserFeedbackModal(false)
    setFeedbackDescription('')
  }

  return (
    <Styled.Subheader>
      <div className="subheader__main">
        <div className="subheader__breadcrumbs">
          <Link to="/">Dashboard</Link>
          {!is_dashboard ? <span className="breadcrumbs__separator">&gt;</span> : null}
          <Breadcrumbs />
        </div>
      </div>
      <div className="subheader__toolbar hide-in-percy">
        <a href={process.env.REACT_APP_CUSTOMER_SUPPORT_URL} target="_blank" rel="noreferrer">
          <GlobalStyles.Tooltip className="subheader__button button" data-title="Contact Customer Service">
            <i>
              <FontAwesomeIcon icon={faUserHeadset} />
            </i>
          </GlobalStyles.Tooltip>
        </a>
        <a onClick={() => setUserFeedbackModal((userFeedbackModal) => !userFeedbackModal)}>
          <GlobalStyles.Tooltip className="subheader__button button" data-title="Report a Bug">
            <i>
              <FontAwesomeIcon icon={faBug} />
            </i>
          </GlobalStyles.Tooltip>
        </a>
        {!userIsWarehouse && (
          <GlobalStyles.Tooltip
            className="subheader__button button"
            data-title="Show Onboarding Checklist"
            onClick={() => dispatch({ type: 'showOnboarding' })}
          >
            <i>
              <FontAwesomeIcon icon={faClipboardListCheck} />
            </i>
          </GlobalStyles.Tooltip>
        )}
        <Link to="/billing/pricing-tier/">
          <GlobalStyles.Tooltip className="subheader__button button" data-title="Pricing Tier">
            <i>
              <FontAwesomeIcon icon={faDollarSign} />
            </i>
          </GlobalStyles.Tooltip>
        </Link>
        <Link to="/billing/company/">
          <GlobalStyles.Tooltip className="subheader__button button" data-title="Billing">
            <i>
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
            </i>
          </GlobalStyles.Tooltip>
        </Link>
        <span ref={node}>
          <GlobalStyles.Tooltip
            onClick={() => setOpen(!isOpen)}
            className="subheader__button button"
            data-title="Create"
          >
            <i>
              <FontAwesomeIcon icon={faPlus} />
            </i>
          </GlobalStyles.Tooltip>
          {isOpen ? (
            <div className="subheader__dropdown-menu">
              <ul role="tablist">
                <SubNavItem url={'/order/new/'} icon={faShoppingBasket} title={'Order'} />
                {userIsWarehouse ? <SubNavItem url={'/package/new/'} icon={faBox} title={'Package'} /> : null}
                <SubNavItem url={'/product/new/'} icon={faCubes} title={'Product'} />
                {/* <SubNavItem url={"/"} icon={faCube} title={"Product Variant"} /> */}
                <SubNavItem url={'/company/email-notifications/'} icon={faBell} title={'Notification'} />
                <SubNavItem url={'/asn/new/'} icon={faRampLoading} title={'ASN'} />
              </ul>
            </div>
          ) : null}
        </span>
        <GlobalStyles.Tooltip className="subheader__button-date button hide-in-percy" data-title="Dashboard Daterange">
          <span className="time-frame" id="kt_dashboard_daterangepicker_title">
            Today:&nbsp;
          </span>
          <span className="date" id="kt_dashboard_daterangepicker_date" style={{ minWidth: 95 }}>
            <Clock format={'MMM D, HH:mm:ss'} ticking={true} />
          </span>
          <i>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </i>
        </GlobalStyles.Tooltip>
      </div>

      <GenericModal
        heading={
          <>
            Report An Issue
            <FontAwesomeIcon icon={faBug} style={{ color: theme.colors.mySin, marginLeft: '7px' }} />
          </>
        }
        show={userFeedbackModal}
        size="lg"
        onHide={() => setUserFeedbackModal(false)}
        buttons={
          <>
            <GlobalStyles.Button onClick={(e) => handleSubmit(e)} style={{ minWidth: 150, margin: 0, float: 'right' }}>
              Submit Report
            </GlobalStyles.Button>
            <GlobalStyles.Button
              style={{ minWidth: 125 }}
              className="secondary"
              onClick={() => setUserFeedbackModal(false)}
            >
              Close
            </GlobalStyles.Button>
          </>
        }
      >
        <div style={{ padding: '1em' }}>
          <StatusBarStyled style={{ backgroundColor: theme.colors.ghostWhite }}>
            <div className="header-item">
              <div className="status-icon">
                <FontAwesomeIcon icon={faInfoSquare} style={{ color: '#800080' }} />
              </div>
              <div className="status-text">
                <strong style={{ fontWeight: 500 }}>Note</strong>: Be as specific as possible when reporting to help us
                make your experience better. Thank you!
              </div>
            </div>
          </StatusBarStyled>
          <Form.Control
            as="textarea"
            maxLength={1000}
            style={{ resize: 'none' }}
            placeholder="Error notes..."
            rows={7}
            value={feedbackDescription}
            onChange={(e) => setFeedbackDescription(e.target.value)}
          />
        </div>
      </GenericModal>
    </Styled.Subheader>
  )
}
