import React, { useState, useRef } from 'react'
import { globalState } from '../../../store'
import { usePost } from '../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Form, Row } from 'react-bootstrap'
import GenericModal from '../../../components/component-items/modal'

import { ACHModalProps } from './types'

export const ACHModal = ({ open, setOpen }: ACHModalProps) => {
  const node = useRef<HTMLFormElement>(null)
  const {
    state: { csrf },
  } = globalState()
  const [validated, setValidated] = useState(false)
  const [achData, setACHData] = useState({
    account_number: '',
    routing_number: '',
    account_type: 'CHECKING',
  })

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setACHData({ ...achData, [name]: value })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    usePost('/api/billing/ach', { ...achData }, csrf)
  }

  return (
    <GenericModal
      heading={'Add ACH'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleSubmit}>
          Add ACH
        </GlobalStyles.Button>
      }
    >
      <div style={{ width: '100%', padding: '2em 4em' }}>
        <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit} style={{ padding: '2em 0' }}>
          <Row>
            <Form.Group className="required">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="number"
                name="account_number"
                placeholder="Account Number"
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter your account number here.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="required">
              <Form.Label>Routing Number</Form.Label>
              <Form.Control
                type="number"
                name="routing_number"
                placeholder="Routing Number"
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter your 9 digit routing number here
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="required">
              <Form.Label>Account Type</Form.Label>
              <Form.Select name="account_type" onChange={handleInputChange} required>
                <option value={'CHECKING'}>Checking</option>
                <option value={'SAVINGS'}>Savings</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid country.</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </GenericModal>
  )
}
