import React from 'react'
import { usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { OrderStatusBox } from '../../../components/component-items/status-box'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

import { PaymentMethodsModalProps } from './types'

export const PaymentMethodsModal = ({ open, setOpen, paymentMethods }: PaymentMethodsModalProps) => {
  const {
    state: { csrf },
  } = globalState()
  return (
    <GenericModal
      heading={'Payment Methods'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div
        style={{ width: '100%', padding: '2em 4em', display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}
      >
        {paymentMethods
          .filter((p: any) => !p.ach_id)
          .map((p: any, id: number) => (
            <div key={id}>
              <Cards
                preview
                issuer={p.card_type === 'MC' ? 'mastercard' : 'visa'}
                cvc={p.ccv}
                expiry={p.exp_month + p.exp_year}
                name={p.billing_name}
                number={'************' + p.last_digits}
              />
              <div style={{ display: 'flex', justifyContent: 'center', padding: '2em 0' }}>
                {!p.default ? (
                  <GlobalStyles.Button
                    style={{ minWidth: 125 }}
                    className="secondary"
                    onClick={() => usePost(`/api/billing/make_default/${p.id}/`, {}, csrf)}
                  >
                    Make Default
                  </GlobalStyles.Button>
                ) : null}
                {p.default ? <OrderStatusBox style={{ margin: 'auto' }} status={'Primary Card'} /> : null}
                <GlobalStyles.Button
                  style={{ minWidth: 125 }}
                  className="secondary"
                  onClick={() => usePost(`/api/billing/delete/${p.id}/`, {}, csrf)}
                >
                  Delete
                </GlobalStyles.Button>
              </div>
            </div>
          ))}
        {!paymentMethods.length ? (
          <div style={{ margin: 'auto', minHeight: 300 }}>
            <div
              role="img"
              aria-label="sleeping"
              style={{ fontSize: '4em', display: 'flex', justifyContent: 'center' }}
            >
              💳{' '}
            </div>
            No Payment Methods Added!
          </div>
        ) : null}
      </div>
      <div style={{ width: '100%', padding: '2em 4em' }}>
        {paymentMethods.filter((p: any) => p.ach_id).length > 0 ? <h3>ACH Methods</h3> : null}
        {paymentMethods
          .filter((p: any) => p.ach_id)
          .map((p: any, id: number) => (
            <div key={id}>
              ACH ID: XXXXX{p.last_digits}
              <div style={{ display: 'inline-block', justifyContent: 'center', padding: '1em 0.5em' }}>
                {!p.default ? (
                  <GlobalStyles.Button
                    style={{ minWidth: 125 }}
                    className="secondary"
                    onClick={() => usePost(`/api/billing/make_default/${p.id}/`, {}, csrf)}
                  >
                    Make Default
                  </GlobalStyles.Button>
                ) : null}
                {p.default ? <OrderStatusBox style={{ marginLeft: '.5em' }} status={'Primary Method'} /> : null}

                <GlobalStyles.Button
                  style={{ minWidth: 125 }}
                  className="secondary"
                  onClick={() => usePost(`/api/billing/delete/${p.id}/`, {}, csrf)}
                >
                  Delete
                </GlobalStyles.Button>
              </div>
            </div>
          ))}
      </div>
    </GenericModal>
  )
}
