import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFetch, usePost, notify } from '../../components/component-items/helpers'
import Select from 'react-select'
import { globalState } from '../../store'
import styled from 'styled-components'

// Components
import Pager, { Pages } from '../../components/component-items/pager'
import ButtonDropdown from '../../components/component-items/button-dropdown'
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'
import { OrderStatusBox, StatusBoxStyled } from '../../components/component-items/status-box'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { Badge, Form, InputGroup } from 'react-bootstrap'
import { TableRow, FilterListItem, DateRangeStyled } from './styles'
import { SearchBox } from '../../components/component-items/search'
import { Row, Col } from 'react-bootstrap'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'
import { faFire } from '@fortawesome/pro-duotone-svg-icons'

// Enums
import { OrderStatus } from '../../enums'

import { FilterOrdersProps, ExportOrdersProps } from './types'

const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
    div[id^='react-select-3-option'] {
      &:hover {
        background-color: #deebff;
      }
      :not(:hover) {
        transition: background-color 0.1s linear;
        -webkit-transition: background-color 0.1s linear;
      }
    }
  }
  div[id^='react-select-3']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-'][id$='-group-'][id*='-heading']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-3-group']:hover + div > div {
    background-color: #deebff !important;
  }
`

const HeaderHover = styled.div`
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
`

const unfulfilledGroup = [
  { value: 70, label: 'Paused' },
  { value: 80, label: 'Backordered' },
  { value: 90, label: 'Received' },
]

const inFulfillmentGroup = [
  { value: 100, label: 'Processing' },
  { value: 110, label: 'Picked' },
  { value: 120, label: 'Packed' },
  { value: 145, label: 'Label Purchase Failed' },
  { value: 150, label: 'Rate Limit Exceeded' },
]
const fulfilledGroup = [
  { value: 160, label: 'Label Purchased' },
  { value: 200, label: 'Shipped' },
  { value: 300, label: 'Delivered' },
]

const colourStyles = {
  menu: (styles: any) => ({ ...styles, zIndex: 1000 }),
  option: (styles: any, { data }: any) => {
    return {
      ...styles,
      zIndex: 1000,
      ...(data.group ? { fontWeight: 'normal' } : { paddingLeft: '30px' }),
    }
  },
}

const createGroup = (groupName: string, options: any[], setValue: (args: any) => void) => {
  return {
    label: (() => {
      return (
        <HeaderHover
          onClick={() => setValue((value: any) => value.concat(options.filter((grpOpt) => !value.includes(grpOpt))))}
        >
          {groupName}
        </HeaderHover>
      )
    })(),
    options: options,
  }
}

export const ExportOrders = ({ showExportModal, setExportModal, url, body }: ExportOrdersProps) => {
  let history = useHistory()
  const {
    state: { csrf },
  } = globalState()
  const [exportType, setExportType] = useState('order')

  const handleExportOrders = async () => {
    const reload = false
    const stringifyBody = true
    const return_response = true
    const resp = await usePost(
      url,
      Object.assign(body, { ...{ exportType } }),
      csrf,
      reload,
      stringifyBody,
      return_response
    )
    if (resp.success) {
      notify({
        type: 'success',
        message:
          'Orders have been requested, you will be redirect to the reports page and will receive an email once the report is generated.',
      })
      history.push('/company/reports/')
    }
  }

  return (
    <GenericModal
      heading={'Export Orders'}
      show={showExportModal}
      size="lg"
      onHide={() => setExportModal(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleExportOrders}>
          Export
        </GlobalStyles.Button>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Export Order List</Form.Label>
          <Form.Select value={exportType} onChange={(e) => setExportType(e.target.value)}>
            <optgroup label="Export Type">
              <option value={'order'}>Order Detail Export</option>
              <option value={'shipment'}>Shipment Detail Export</option>
              <option value={'lot_numbers'}>Lot Number Export</option>
            </optgroup>
          </Form.Select>
        </FilterListItem>
      </div>
    </GenericModal>
  )
}

export const FilterOrders = ({ showFilterModal, setFilterModal, setSort, setPage, is_company }: FilterOrdersProps) => {
  let history = useHistory()
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [addDate, setAddDate] = useState(false)
  const [sku, setSku] = useState('')

  const [statuses, setStatuses] = useState([])
  let statusOptions = [
    { value: 10, label: 'Cancelled' },
    createGroup('--Unfulfilled--', unfulfilledGroup, setStatuses),
    createGroup('--In Fulfillment--', inFulfillmentGroup, setStatuses),
    createGroup('--Fulfilled--', fulfilledGroup, setStatuses),
  ]

  const [dateRangeType, setDateRangeType] = useState('')
  const [dateTypeOptions, setDateTypeOptions] = useState<any>([])

  const defaultTags = [
    { label: 'B2B', value: 'b_to_b' },
    { label: 'EDI', value: 'edi' },
    { label: 'Hot', value: 'hot' },
  ]
  const [tags, setTags] = useState([])

  const startDate = addDate ? state[0].startDate.toLocaleDateString('en-US').replace(/\//g, '-') : ''
  const endDate = addDate ? state[0].endDate.toLocaleDateString('en-US').replace(/\//g, '-') : ''

  useEffect(() => {
    // Check if statuses only fulfilled (shipped and delivered)
    var is_fulfilled = true
    statuses.forEach(function (entry) {
      if (![OrderStatus.LabelPurchased, OrderStatus.Shipped, OrderStatus.Delivered].includes(entry['value'])) {
        is_fulfilled = false
      }
    })

    var is_shipped_or_delivered = true
    statuses.forEach(function (entry) {
      if (![OrderStatus.Shipped, OrderStatus.Delivered].includes(entry['value'])) {
        is_shipped_or_delivered = false
      }
    })

    var is_delivered = statuses.length === 1 && statuses[0]['value'] === OrderStatus.Delivered

    // 1. Label Purchased + Shipped + Delivered -> Show Fulfilled
    // 2. Shipped + Delivered -> Show Shipped + Fulfilled
    // 3. Delivered -> Show all 3

    // When order status is shipped or greater, show all options
    if (is_delivered) {
      setDateTypeOptions([
        { value: '', label: '--' },
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between' },
        { value: 'delivered_between', label: 'Delivered Between' },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else if (is_shipped_or_delivered) {
      setDateTypeOptions([
        { value: '', label: '--' },
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between' },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else if (statuses.length === 0 || is_fulfilled) {
      setDateTypeOptions([
        { value: '', label: '--' },
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between [Invalid]', isDisabled: true },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between' },
      ])
    } else {
      setDateTypeOptions([
        { value: '', label: '--' },
        { value: 'received_between', label: 'Received Between' },
        { value: 'maturation_between', label: 'Maturation Between' },
        { value: 'shipped_between', label: 'Shipped Between [Invalid]', isDisabled: true },
        { value: 'delivered_between', label: 'Delivered Between [Invalid]', isDisabled: true },
        { value: 'fulfilled_between', label: 'Fulfilled Between [Invalid]', isDisabled: true },
      ])
      if (dateRangeType) setDateRangeType('')
    }
  }, [statuses])
  const handleFilter = () => {
    if ((dateRangeType && (!startDate || !endDate)) || (startDate && !dateRangeType)) {
      toast('Filter by Date Range must include range type and date range', {
        type: 'error',
      })
      return
    }
    setFilterModal(false)
    setSort('All')

    var tags_list: never[] = []
    tags.forEach((entry) => {
      tags_list.push(entry['value'])
    })

    var status_list: never[] = []
    statuses.forEach((entry) => {
      status_list.push(entry['value'])
    })

    history.push(
      '?sku=' +
        sku +
        '&start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&filter_status=' +
        status_list.join(',') +
        '&date_range_type=' +
        dateRangeType +
        '&page=1' +
        '&tags=' +
        tags_list.join(',')
    )
  }

  const handleReset = () => {
    setSku('')
    setAddDate(false)
    setStatuses([])
    setDateRangeType('')
    setFilterModal(false)
    setPage(1)
    setTags([])
    history.push('?')
  }

  return (
    <GenericModal
      heading={'Filter Orders'}
      show={showFilterModal}
      size="lg"
      onHide={() => setFilterModal(false)}
      buttons={
        <>
          <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={handleReset}>
            Reset
          </GlobalStyles.Button>

          <GlobalStyles.Button style={{ minWidth: 125 }} onClick={handleFilter}>
            Filter
          </GlobalStyles.Button>
        </>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Filter by Order Type</Form.Label>
          <MultiSelect>
            <Select
              options={defaultTags}
              value={tags}
              onChange={(e: any) => setTags(e)}
              closeMenuOnSelect={false}
              isMulti
              className="basic-multi-select"
            />
          </MultiSelect>
        </FilterListItem>
        {is_company ? (
          <FilterListItem>
            <Form.Label>Filter by SKU</Form.Label>
            <Form.Control type="text" value={sku} onChange={(e) => setSku(e.target.value)} placeholder="Enter a SKU" />
          </FilterListItem>
        ) : null}
        <FilterListItem>
          <Form.Label>Filter by Order Status</Form.Label>
          <MultiSelect>
            <Select
              options={statusOptions}
              value={statuses}
              onChange={(option: any) => {
                return setStatuses(option)
              }}
              closeMenuOnSelect={false}
              isMulti
              className="basic-multi-select"
            />
          </MultiSelect>
        </FilterListItem>

        <FilterListItem className="large" style={{ marginTop: '20px' }}>
          <Row className="justify-content-md-center">
            <Form.Label className="text-center">Filter by Date Range</Form.Label>
            <Col md="6">
              <Select
                options={dateTypeOptions}
                value={dateTypeOptions.find((option: any) => option.value === dateRangeType)}
                onChange={(e: any) => setDateRangeType(e.value)}
                styles={colourStyles}
              />
            </Col>
            <Col md="6">
              <InputGroup>
                <Form.Control
                  type="text"
                  value={startDate ? startDate + ' to ' + endDate : ''}
                  onChange={() => null}
                  onFocus={() => setAddDate(true)}
                  placeholder="Choose A Date"
                  aria-describedby="inputGroupAppend"
                  required
                />
                <GlobalStyles.ClearInputButton onClick={() => setAddDate(false)}>Clear</GlobalStyles.ClearInputButton>
                <InputGroup.Text id="inputGroupAppend">
                  <FontAwesomeIcon icon={faCalendarWeek} />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        </FilterListItem>

        {addDate ? (
          <DateRangeStyled>
            <DateRangePicker
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              months={1}
              minDate={new Date(2018, 1, 1)}
              maxDate={new Date()}
              editableDateInputs={true}
              ranges={state}
              direction="horizontal"
              className="hide-in-percy"
            />
          </DateRangeStyled>
        ) : null}
      </div>
    </GenericModal>
  )
}

export const OrderList = () => {
  let history = useHistory()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const {
    state: { userIsWarehouse, actAs },
  } = globalState()

  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const [search, setSearch] = useState('')
  const [sku, setSku] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [statuses, setStatuses] = useState('')
  const [dateRangeType, setDateRangeType] = useState('')
  const [tags, setTags] = useState('')

  const sortOptions: any = {
    All: '',
    Unfulfilled: 'unfulfilled',
    'In Fulfillment': 'in_fulfillment',
    Fulfilled: 'fulfilled',
  }

  // Assign sort based on url params first
  const [sort, setSort] = useState('All')
  const [sortRow, setSortRow] = useState({})
  const [showFilterModal, setFilterModal] = useState(false)
  const [showExportModal, setExportModal] = useState(false)

  const url =
    '/api/order/order_list/?range=' +
    rows +
    '&page=' +
    (page - 1) +
    '&fulfillment=' +
    sortOptions[sort] +
    '&order_by=' +
    Object.keys(sortRow) +
    '&sort_row=' +
    Object.values(sortRow) +
    '&q=' +
    search +
    '&sku=' +
    sku +
    '&start_date=' +
    startDate +
    '&end_date=' +
    endDate +
    '&filter_status=' +
    statuses +
    '&date_range_type=' +
    dateRangeType +
    '&tags=' +
    tags

  const { response: res, loaded, placeholder, error }: any = useFetch(url, {})

  const body = {
    range: rows,
    page: page - 1,
    fulfillment: sortOptions[sort],
    order_by: Object.keys(sortRow),
    sort_row: Object.values(sortRow),
    q: search,
    sku: sku,
    start_date: startDate,
    end_date: endDate,
    filter_status: statuses,
    date_range_type: dateRangeType,
    tags: tags,
    download: true,
  }

  const [loading, setLoading] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  useEffect(() => {
    let counter = 0
    if (sort !== 'All') counter++
    if (statuses) counter++
    if (startDate || endDate) counter++
    setFilterCount(counter)
  }, [startDate, endDate, statuses, sort])

  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  useEffect(() => {
    const status = urlParams.get('fulfillment')
    setSku(urlParams.get('sku') || '')
    setStartDate(urlParams.get('start_date') || '')
    setEndDate(urlParams.get('end_date') || '')
    setStatuses(urlParams.get('filter_status') || '')
    setDateRangeType(urlParams.get('date_range_type') || '')
    setTags(urlParams.get('tags') || '')
    const index = status ? Object.values(sortOptions).indexOf(status) : 0
    setSort(Object.keys(sortOptions)[index])
    setPage(1)
  }, [location])

  const total = loaded ? res.total : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1

  const customSetSort = (e: any) => {
    const params = new URLSearchParams(history.location.search)
    params.set('fulfillment', sortOptions[e])
    history.push(`/order/?${params.toString()}`)
    setPage(1)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Order List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <ButtonDropdown
            title={'Fulfillment'}
            sort={sort}
            setSort={customSetSort}
            options={Object.keys(sortOptions)}
          />
          <SearchBox
            style={{ marginLeft: '1em' }}
            {...{ search, setSearch, loading, setLoading, page, setPage }}
            setFocus
            updateURL
          />
          <GlobalStyles.Button onClick={() => setFilterModal(true)}>
            Filter {filterCount > 0 && <Badge pill>{filterCount}</Badge>}
          </GlobalStyles.Button>
          <FilterOrders
            {...{ showFilterModal, setFilterModal, setSort: customSetSort, setPage: setPage }}
            is_company={loaded ? res.company : false}
          />
          {!userIsWarehouse || actAs ? (
            <>
              <GlobalStyles.Button
                className="secondary"
                onClick={() => {
                  setExportModal(true)
                }}
              >
                Export As CSV
              </GlobalStyles.Button>
              <ExportOrders {...{ showExportModal, setExportModal, body }} url={url + '&download=true'} />
            </>
          ) : null}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort center title="Order Number" sortKey={'id'} sort={sortRow} setSort={setSortRow} />
              <THSort center title="Created" sort={sortRow} setSort={setSortRow} />
              <th>
                <span className="text">Address</span>
              </th>
              <THSort title="Shipping" sort={sortRow} setSort={setSortRow} />
              <th>
                <span className="text center">Tags</span>
              </th>
              <th>
                <span className="text center">Order Items</span>
              </th>
              <THSort center title="Status" sort={sortRow} setSort={setSortRow} />
            </tr>
          </thead>
          <tbody>
            {loaded
              ? res.orders.map(
                  (
                    {
                      id,
                      remote_id,
                      name,
                      address,
                      shipping,
                      email,
                      created,
                      items,
                      status,
                      status_id,
                      b2b,
                      hot,
                      edi,
                    }: any,
                    k_id: number
                  ) => (
                    <TableRow key={k_id}>
                      <TableCell
                        center
                        input={
                          <Link to={`/order/${id}/`} style={{ maxWidth: 150 }}>
                            {id}
                          </Link>
                        }
                      >
                        {remote_id ? <div>({remote_id})</div> : null}
                      </TableCell>
                      <TableCell center input={<div style={{ minWidth: 80 }}>{created.date}</div>}>
                        <div>{created.time}</div>
                      </TableCell>
                      <TableCell input={name}>
                        {address ? (
                          <>
                            <div>
                              {address.line1}
                              {address.line2 ? ' - ' + address.line2 : ''} {address.state} {address.zip}{' '}
                              {address.country_code}
                            </div>
                            <div>
                              {email} | {address.phone}
                            </div>
                          </>
                        ) : null}
                      </TableCell>
                      <TableCell input={shipping.method}>
                        {
                          <>
                            <div>{shipping.tracking_method}</div>
                            <div>
                              <a href={shipping.tracking_url} rel="noreferrer" target="__blank">
                                {shipping.tracking}
                              </a>
                            </div>
                          </>
                        }
                      </TableCell>
                      <TableCell center>
                        {b2b && (
                          <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'purple bold'}>
                            B2B
                          </StatusBoxStyled>
                        )}
                        {hot && (
                          <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'red bold'}>
                            <FontAwesomeIcon icon={faFire} /> HOT
                          </StatusBoxStyled>
                        )}
                        {edi && (
                          <StatusBoxStyled style={{ width: 75, margin: '0.5em auto' }} className={'blue bold'}>
                            EDI
                          </StatusBoxStyled>
                        )}
                      </TableCell>
                      <TableCell>
                        {items.length ? (
                          <div
                            style={{
                              maxHeight: 200,
                              overflow: 'scroll',
                              boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                            }}
                          >
                            <GlobalStyles.DataTable>
                              <tbody>
                                {items.map(({ id, img, name, sku, qty }: any) => (
                                  <TableRow key={id}>
                                    <TableCell
                                      center
                                      input={<img src={img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />}
                                    />
                                    <TableCell input={<Link to={`/product/variant/${id}/`}>{sku}</Link>}>
                                      <div>{name}</div>
                                    </TableCell>
                                    <TableCell input={qty} center />
                                  </TableRow>
                                ))}
                              </tbody>
                            </GlobalStyles.DataTable>
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell
                        center
                        input={<OrderStatusBox style={{ width: 132, margin: 'auto' }} {...{ status, status_id }} />}
                      />
                    </TableRow>
                  )
                )
              : null}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
