import React, { useState, useContext, useRef } from 'react'
import { store } from '../../../store'
import { useFetch, StickyElement } from '../../../components/component-items/helpers'
import { Dropdown, OrderOptions } from './styles'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'

// Cards
import { StatusBar } from './status-bar'
import { TransactionHistory } from './transaction-history'
import { RechargeSettings } from './recharge'

// Modals
import { CreditMerchantModal } from './credit-modal'
import { ChargeMerchantModal } from './debit-modal'
import { MonthlyStatementsModal } from './statements-modal'
import { MakePaymentModal } from './make-payment-modal'
import { PaymentsModal } from './payments-modal'
import { ACHModal } from './ach-modal'
import { AddPaymentMethodModal } from './add-payment-method-modal'
import { PaymentMethodsModal } from './payment-methods-modal'
import { StorageChargesModal } from './storage-charges-modal'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import {
  faCreditCard,
  faMoneyCheck,
  faMinusHexagon,
  faPlusHexagon,
  faFileInvoiceDollar,
  faReceipt,
  faHandHoldingUsd,
  faWarehouseAlt,
  faStars,
  faList,
  faLayerPlus,
} from '@fortawesome/pro-duotone-svg-icons'

import { BillingOptionProps } from './types'
import { AddOns } from './add-ons'
import { AddRecurringTransaction } from './add-ons/create'
import { DropdownItem } from '../../../components/component-items/export-tools'
import { AddOnTemplates } from './add-ons/templates'

const BillingOption = ({ url, onClick, icon, title, arrow, children, node }: BillingOptionProps) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const BillingPage = () => {
  const globalState = useContext(store)
  const {
    state: { userIsWarehouse, userIsExecutive },
  } = globalState

  const addOnNode = useRef(null)
  const [addOnDropdown, setAddOnDropdown] = useState(false)

  const [openChargeMerchantModal, setChargeMerchantModal] = useState(false)
  const [openCreditMerchantModal, setCreditMerchantModal] = useState(false)
  const [openStatementModal, setStatementModal] = useState(false)
  const [openMakePaymentModal, setMakePaymentModal] = useState(false)
  const [openPaymentsModal, setPaymentsModal] = useState(false)
  const [addACHModal, setAddACHModal] = useState(false)
  const [openAddPaymentModal, setAddPaymentModal] = useState(false)
  const [openPaymentMethodsModal, setPaymentMethodsModal] = useState(false)
  const [storageChargesModal, setStorageChargesModal] = useState(false)
  const [openRecurrenceModal, setOpenRecurrenceModal] = useState(false)
  const [openAddOnTemplates, setOpenAddOnTemplates] = useState(false)

  const { response: resp, loaded, error, placeholder }: any = useFetch('/api/billing/billing-data/', {})

  const activeWarehouses = loaded ? resp.activeWarehouses : []
  const countries = loaded ? resp.countries : []
  const paymentMethods = loaded ? resp.paymentMethods : []
  const categories = loaded ? resp.categories : []
  const rechargeAmount = loaded ? resp.rechargeAmount : '250'
  const rechargeThreshold = loaded ? resp.rechargeThreshold : '0'
  const primary = loaded ? resp.primaryCard : {}
  const secondary = loaded ? resp.secondaryCard : {}

  return (
    <>
      <StatusBar
        balance={loaded ? resp.balance : '0.00'}
        lastPayment={loaded ? resp.lastPayment : ''}
        company={loaded ? resp.company : ''}
        owing={loaded ? resp.owing : false}
      />
      <div className="row">
        <div className="col-xl-2">
          <StickyElement>
            <OrderOptions>
              <ul>
                {userIsWarehouse ? (
                  <GlobalStyles.Tooltip data-title="Add additional charges for merchant">
                    <BillingOption
                      onClick={() => setChargeMerchantModal(true)}
                      icon={faPlusHexagon}
                      title={'Charge Merchant'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {userIsWarehouse ? (
                  <GlobalStyles.Tooltip data-title="Add payments to merchant">
                    <BillingOption
                      onClick={() => setCreditMerchantModal(true)}
                      icon={faMinusHexagon}
                      title={'Credit Merchant'}
                    />
                  </GlobalStyles.Tooltip>
                ) : null}
                {paymentMethods.length ? (
                  <BillingOption
                    onClick={() => setMakePaymentModal(true)}
                    icon={faHandHoldingUsd}
                    title={'Make Payment'}
                  />
                ) : null}
                <GlobalStyles.Tooltip data-title="List of monthly statements with PDF breakdowns">
                  <BillingOption
                    onClick={() => setStatementModal(true)}
                    icon={faFileInvoiceDollar}
                    title={'Statements'}
                  />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="List of payments to merchant">
                  <BillingOption onClick={() => setPaymentsModal(true)} icon={faReceipt} title={'Payments'} />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="Add a credit card as form of payment">
                  <BillingOption
                    onClick={() => setAddPaymentModal(true)}
                    icon={faCreditCard}
                    title={'Add Credit Card'}
                  />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="Add a banking account as form of payment">
                  <BillingOption onClick={() => setAddACHModal(true)} icon={faCreditCard} title={'Add ACH'} />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="List of Credit Card and ACH payment methods">
                  <BillingOption
                    onClick={() => setPaymentMethodsModal(true)}
                    icon={faMoneyCheck}
                    title={'Payment Methods'}
                  />
                </GlobalStyles.Tooltip>
                {userIsExecutive && (
                  <GlobalStyles.Tooltip data-title="Add recurring transactions">
                    <BillingOption
                      onClick={() => setAddOnDropdown((a) => !a)}
                      icon={faStars}
                      title={'Add-Ons'}
                      arrow={true}
                      node={addOnNode}
                    >
                      {addOnDropdown && (
                        <Dropdown>
                          <GlobalStyles.Tooltip data-title="Add an existing recurring transaction">
                            <DropdownItem
                              onClick={() => setOpenAddOnTemplates(true)}
                              icon={faPlusHexagon}
                              text={'Select Add On'}
                            />
                          </GlobalStyles.Tooltip>
                          <GlobalStyles.Tooltip data-title="Create a new recurring transaction">
                            <DropdownItem
                              onClick={() => setOpenRecurrenceModal(true)}
                              icon={faLayerPlus}
                              text={'Create Add On'}
                            />
                          </GlobalStyles.Tooltip>
                        </Dropdown>
                      )}
                    </BillingOption>
                  </GlobalStyles.Tooltip>
                )}
                {userIsExecutive && (
                  <GlobalStyles.Tooltip data-title="Run storage charges prematurely or rerun them for a prior month">
                    <BillingOption
                      onClick={() => setStorageChargesModal(true)}
                      icon={faWarehouseAlt}
                      title={'Run Storage Charges'}
                    />
                  </GlobalStyles.Tooltip>
                )}
              </ul>
            </OrderOptions>
          </StickyElement>
        </div>
        <div className="col-xl-7">
          <div className="row">
            <div className="col-12">
              <AddOns />
              <TransactionHistory {...{ categories }} />
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <StickyElement>
            <RechargeSettings {...{ rechargeAmount, rechargeThreshold, primary, secondary }} />
          </StickyElement>
        </div>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      <ChargeMerchantModal open={openChargeMerchantModal} setOpen={setChargeMerchantModal} {...{ activeWarehouses }} />
      <CreditMerchantModal open={openCreditMerchantModal} setOpen={setCreditMerchantModal} {...{ activeWarehouses }} />
      <MakePaymentModal
        open={openMakePaymentModal}
        setOpen={setMakePaymentModal}
        {...{ rechargeAmount, paymentMethods, primary }}
      />
      <MonthlyStatementsModal open={openStatementModal} setOpen={setStatementModal} />
      <PaymentsModal open={openPaymentsModal} setOpen={setPaymentsModal} />
      <ACHModal open={addACHModal} setOpen={setAddACHModal} />
      <AddPaymentMethodModal open={openAddPaymentModal} setOpen={setAddPaymentModal} {...{ countries }} />
      <PaymentMethodsModal open={openPaymentMethodsModal} setOpen={setPaymentMethodsModal} {...{ paymentMethods }} />
      <StorageChargesModal open={storageChargesModal} setOpen={setStorageChargesModal} />
      <AddRecurringTransaction open={openRecurrenceModal} setOpen={setOpenRecurrenceModal} {...{ categories }} />
      <AddOnTemplates {...{ categories }} open={openAddOnTemplates} setOpen={setOpenAddOnTemplates} />
    </>
  )
}
