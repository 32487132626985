import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { globalState } from '../../../store'

import { Styled } from './styles'
import { GlobalStyled } from '../../component-items/styles'

// Components
import HamburgerMenu from '../../component-items/hamburger-menu'
import { DropDown } from '../../component-items/helpers'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faList,
  faTachometer,
  faShippingFast,
  faFilePlus,
  faFileCsv,
  faFileChartLine,
  faFileInvoiceDollar,
  faInventory,
  faRampLoading,
  faBoxAlt,
  faExchange,
  faLocation,
  faTasks,
  faLayerGroup,
  faSwatchbook,
  faBuilding,
  faFileCheck,
  faBoxCheck,
  faCubes,
  faBookSpells,
} from '@fortawesome/pro-duotone-svg-icons'

import { MenuItemProps, MenuSectionProps, MenuSubSectionProps } from './types'

const MenuItem = ({ text, url, icon, children, submenu, open, isOpen }: MenuItemProps) => {
  const location = useLocation()
  const [active, setActive] = useState(false)
  useEffect(() => {
    var active_temp = !!(url === location.pathname || url === location.pathname + location.search)
    if (url === location.pathname && location.search !== '') {
      active_temp = false
    }
    setActive(active_temp)
  }, [location])

  const body = (
    <>
      {submenu ? (
        <Styled.MenuBullet>
          <span />
        </Styled.MenuBullet>
      ) : (
        <i className="menu__link-icon">
          <FontAwesomeIcon icon={icon} />
        </i>
      )}
      <span className="aside-menu_item">{text}</span>
      {children}
    </>
  )
  return (
    <Styled.MenuItem submenu={submenu} aria-haspopup="true">
      {url ? (
        <Link to={url} onClick={open} className={active ? 'active' : isOpen ? 'open' : ''}>
          {body}
        </Link>
      ) : (
        <a onClick={open} className={active ? 'active' : isOpen ? 'open' : ''}>
          {body}
        </a>
      )}
    </Styled.MenuItem>
  )
}

const MenuSection = ({ text }: MenuSectionProps) => (
  <Styled.MenuSection className="aside-menu_header">
    <h4>{text}</h4>
  </Styled.MenuSection>
)

const MenuSubSection = ({ children, text, icon }: MenuSubSectionProps) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <Styled.MenuItem aria-haspopup="true">
      <MenuItem text={text} icon={icon} open={() => setOpen(!isOpen)} isOpen={isOpen}>
        <GlobalStyled.Arrow className={`aside-menu_item ${isOpen ? 'active' : ''}`} />
      </MenuItem>
      {/* @ts-ignore */}
      <DropDown open={isOpen}>
        <Styled.Submenu>
          <ul>{children}</ul>
        </Styled.Submenu>
      </DropDown>
    </Styled.MenuItem>
  )
}

export const NavigationAside = () => {
  const {
    state: { userIsWarehouse, navOpen, navStayOpen },
    dispatch,
  } = globalState()

  return (
    <Styled.Aside
      onMouseEnter={() => (!navOpen ? dispatch({ type: 'navOpen' }) : null)}
      onMouseLeave={() => (!navStayOpen && navOpen ? dispatch({ type: 'navClose' }) : null)}
      {...{ navOpen }}
    >
      <Styled.AsideBrand {...{ navOpen }}>
        <Styled.BrandLogo {...{ navOpen }}>
          <a href="/">
            <img alt="Logo" width="25px" src={'/api/static/assets/media/logos/ShippingTree_Logo.png'} />
            <span className="menu__link-text">ShippingTree</span>
          </a>
        </Styled.BrandLogo>
        <Styled.AsideHamburgerMenu onClick={() => dispatch({ type: navStayOpen ? 'navCloseSave' : 'navOpenSave' })}>
          <HamburgerMenu active={navStayOpen} />
        </Styled.AsideHamburgerMenu>
      </Styled.AsideBrand>
      <Styled.AsideMenu>
        <ul>
          {userIsWarehouse ? (
            <>
              <MenuItem text={'Dashboard'} url={'/warehouse/dashboard/'} icon={faTachometer} />
              <MenuSection text={'Orders'} />
              <MenuSubSection text={'Order List'} icon={faList}>
                <MenuItem submenu text={'All'} url={'/order/'} />
                <MenuItem submenu text={'UnFulfilled'} url={'/order/?fulfillment=unfulfilled'} />
                <MenuItem submenu text={'In Fulfillment'} url={'/order/?fulfillment=in_fulfillment'} />
                <MenuItem submenu text={'Fulfilled'} url={'/order/?fulfillment=fulfilled'} />
              </MenuSubSection>
              <MenuItem text={'Ship'} url={'/warehouse/start-shipping'} icon={faShippingFast} />
              <MenuItem text={'Create Order'} url={'/order/new/'} icon={faFilePlus} />
              <MenuItem text={'Upload Orders'} url={'/order/upload-orders/'} icon={faFileCsv} />
              <MenuSection text={'Inventory'} />
              <MenuItem text={'Products'} url={'/product/'} icon={faCubes} />
              <MenuItem text={'Packaging'} url={'/package/'} icon={faBoxAlt} />

              <MenuSubSection text={'ASNs'} icon={faRampLoading}>
                <MenuItem submenu text={'ASN List'} url={'/asn/'} />
                <MenuItem submenu text={'Receive'} url={'/warehouse/start-receiving/'} />
              </MenuSubSection>
              <MenuItem text={'Returns'} url={'/return/'} icon={faExchange} />
              <MenuItem text={'Inventory'} url={'/inventory/'} icon={faInventory} />
              <MenuItem text={'Locations'} url={'/location/'} icon={faLocation} />
              <MenuItem text={'Projects'} url={'/project/?filter_status=140'} icon={faTasks} />
              <MenuItem text={'EDI'} url={'/edi/'} icon={faBookSpells} />
              <MenuSection text={'warehouse'} />
              <MenuItem text={'Customers'} url={'/warehouse/companies'} icon={faBuilding} />
              <MenuItem text={'Batch List'} url={'/batch/'} icon={faLayerGroup} />
              <MenuItem text={'Wave Profiles'} url={'/batch/profile/'} icon={faSwatchbook} />
              <MenuItem text={'Exit Scan'} url={'/warehouse/exit-scan/'} icon={faBoxCheck} />
              <MenuItem text={'Postage Manifests'} url={'/postage/manifests/'} icon={faFileCheck} />
              <MenuSection text={'Documents'} />
              <MenuItem text={'Billing'} url={'/billing/company/'} icon={faFileInvoiceDollar} />
              <MenuItem text={'Reports'} url={'/company/reports/'} icon={faFileChartLine} />
            </>
          ) : (
            <>
              <MenuItem text={'Dashboard'} url={'/company/dashboard/'} icon={faTachometer} />
              <MenuSection text={'Orders'} />
              <MenuSubSection text={'Order List'} icon={faList}>
                <MenuItem submenu text={'All'} url={'/order/'} />
                <MenuItem submenu text={'UnFulfilled'} url={'/order/?fulfillment=unfulfilled'} />
                <MenuItem submenu text={'In Fulfillment'} url={'/order/?fulfillment=in_fulfillment'} />
                <MenuItem submenu text={'Fulfilled'} url={'/order/?fulfillment=fulfilled'} />
              </MenuSubSection>
              <MenuItem text={'Create Order'} url={'/order/new/'} icon={faFilePlus} />
              <MenuItem text={'Upload Orders'} url={'/order/upload-orders/'} icon={faFileCsv} />
              <MenuSection text={'Inventory'} />
              <MenuItem text={'Products'} url={'/product/'} icon={faInventory} />
              <MenuItem text={'Packaging'} url={'/package/'} icon={faBoxAlt} />
              <MenuItem text={'ASNs'} url={'/asn/'} icon={faRampLoading} />
              <MenuItem text={'Returns'} url={'/return/'} icon={faExchange} />
              <MenuItem text={'Projects'} url={'/project/?filter_status=140'} icon={faTasks} />
              <MenuSection text={'Documents'} />
              <MenuItem text={'Billing'} url={'/billing/company/'} icon={faFileInvoiceDollar} />
              <MenuItem text={'Reports'} url={'/company/reports/'} icon={faFileChartLine} />
            </>
          )}
        </ul>
      </Styled.AsideMenu>
      {/*
            <Styled.AsideFooter>
                <AsideItem url={"#"} icon={faCog} />
                <AsideItem url={"http://help.shippingtree.co/"} icon={faWrench} />
                <AsideItem url={"#"} icon={faBell} />
                <div className={"aside__footer-item " + (isOpen ? "active" : "")}>
                    <button onClick={() => setOpen(!isOpen)} type="button" aria-haspopup="true" aria-expanded="false">
                        <i><FontAwesomeIcon icon={faPlusCircle} /></i>
                    </button>
                    {isOpen ? <ExportTools title={"Export Tools"} print={"#"} copy={"#"} excel={"#"} csv={"#"} pdf={"#"} /> : null}
                </div>
                <AsideItem url={"#"} icon={faCalendarDay} />
            </Styled.AsideFooter>
            */}
    </Styled.Aside>
  )
}
