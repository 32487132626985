import React, { useState } from 'react'
import { usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'

import { OverrideShippingRateModalProps } from './types'

export const OverrideShippingRateModal = ({
  order,
  rate,
  overrideShippingRateModal,
  setOverrideShippingRateModal,
}: OverrideShippingRateModalProps) => {
  const {
    state: { csrf },
  } = globalState()
  return (
    <GenericModal
      heading={'Override Shipping Limit'}
      show={overrideShippingRateModal}
      onHide={() => setOverrideShippingRateModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() => usePut(`/api/order/${order}/override_shipping_limit/`, {}, csrf)}
        >
          Confirm
        </GlobalStyles.Button>
      }
    >
      <p style={{ fontSize: '1.25rem', textAlign: 'center' }}>
        Please confirm the shipping price of <h2 style={{ marginTop: '10px' }}>${rate}</h2>
      </p>
    </GenericModal>
  )
}
