import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { globalState } from '../../../store'

// Components
import { useFetch, usePost, notify } from '../../../components/component-items/helpers'
import GlobalStyles from '../../../components/component-items/styles'
import Pager, { Pages } from '../../../components/component-items/pager'
import { TableCell } from '../../../components/component-items/datatable'
import CardLoading from '../../../components/component-items/loading-popover'
import { OrderStatusBox } from '../../../components/component-items/status-box'
import { WebhookHeader } from './WebhookHeader'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons'

const enum WebhookStatus {
  Pending = 0,
  Success = 1,
  Failed = 2,
}

const WebhookEventRow = ({ data, webhookId }: any) => {
  const {
    state: { csrf },
  } = globalState()
  const { updated, status, id } = data
  const [eventStatus, setEventStatus] = useState(status)
  const [attempts, setAttempts] = useState(data.attempts || 0)

  const onClickRetry = async () => {
    const res = await usePost(
      `/api/webhooks/${webhookId}/events/${id}/retry/`,
      {},
      csrf,
      false,
      true,
      true,
      true,
      false
    )
    if (res?.ok) {
      notify({ type: 'success', message: 'Successfully received a Webhook' })
      setEventStatus(WebhookStatus.Success)
    } else {
      notify({ type: 'error', message: 'Failed to receive a succesful response' })
    }
    setAttempts(attempts + 1)
  }

  const eventStatusDisplay =
    eventStatus === WebhookStatus.Success ? 'success' : eventStatus === WebhookStatus.Failed ? 'failed' : 'pending'

  return (
    <GlobalStyles.TableRow>
      <TableCell
        center
        input={new Date(updated).toLocaleDateString('en-US') + ' ' + new Date(updated).toLocaleTimeString('en-US')}
      />
      <TableCell center input={attempts} />
      <TableCell center>
        <OrderStatusBox
          style={{ width: '100px', margin: 'auto', textTransform: 'capitalize' }}
          status={eventStatusDisplay}
          status_id={eventStatus === WebhookStatus.Success ? 200 : eventStatus === WebhookStatus.Failed ? '10' : '0'}
        />
      </TableCell>
      <TableCell
        center
        input={
          <div className="dropdown__container">
            {eventStatus !== WebhookStatus.Success ? (
              <button onClick={onClickRetry}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            ) : (
              <span>-</span>
            )}
          </div>
        }
      />
    </GlobalStyles.TableRow>
  )
}

export const WebhookEventsPage = () => {
  const params: any = useParams()
  const [page, setPage] = useState(1)
  const tableRowOptions = [100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const { loaded: loadedWebhook, response: respWebhook }: any = useFetch(`/api/webhooks/${params.id}/`, {})
  const {
    loaded,
    response: resp,
    error,
  }: any = useFetch(`/api/webhooks/${params.id}/events/?limit=${rows}&offset=${(page - 1) * rows}`, {})

  const events = loaded ? resp.results : []
  const total = loaded ? resp.count : 0
  const totalPages = loaded && total ? Math.ceil(total / rows) : 1

  return (
    <>
      {loaded ? (
        <>
          {loadedWebhook && <WebhookHeader webhook={respWebhook} />}
          <GlobalStyles.FullPageCard style={{ minHeight: 500 }}>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                <h3>
                  Webhook Events<small>{total} Total</small>
                </h3>
              </GlobalStyles.CardTitle>
            </GlobalStyles.CardHeader>
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text center">Date Attempted</span>
                  </th>
                  <th>
                    <span className="text center">Attempts</span>
                  </th>
                  <th>
                    <span className="text center">Status</span>
                  </th>
                  <th>
                    <span className="text center">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {events.map((event: any) => (
                  <WebhookEventRow data={event} webhookId={params?.id} key={event.id} />
                ))}
              </tbody>
            </GlobalStyles.DataTable>
            <GlobalStyles.CardFooter>
              <Pages page={page} setPage={setPage} total={totalPages} show={5} />
              <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
            </GlobalStyles.CardFooter>
            {!loaded ? <CardLoading error={error} /> : null}
          </GlobalStyles.FullPageCard>
        </>
      ) : null}
    </>
  )
}
