import React from 'react'
import { useFetch } from '../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import { TableCell } from '../../../components/component-items/datatable'
import { OrderStatusBox } from '../../../components/component-items/status-box'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/pro-duotone-svg-icons'

export const MonthlyStatementsModal = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
  const res: any = useFetch('/api/billing/statement-data/', {})

  const loaded = res.loaded
  const resp = res.response

  const statements = loaded ? resp.statements : []
  const currentMonthlyActivity = loaded ? resp.currentMonthlyActivity : '0.00'

  return (
    <GenericModal
      heading={'Monthly Statements'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setOpen(false)}>
          Close
        </GlobalStyles.Button>
      }
    >
      <div style={{ width: '100%', minHeight: 300 }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Date</span>
              </th>
              <th>
                <span className="text center">Total</span>
              </th>
              <th>
                <span className="text center">Print</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <GlobalStyles.TableRow>
              <TableCell input="Current" />
              <TableCell
                center
                input={<OrderStatusBox style={{ width: 100, margin: 'auto' }} status={currentMonthlyActivity} />}
              />
              <TableCell
                center
                input={
                  <div className="dropdown__container">
                    <button>
                      <a
                        href={`/api/billing/statement/`}
                        rel="noreferrer"
                        target="__blank"
                        style={{ fontSize: '1.5em' }}
                      >
                        <FontAwesomeIcon icon={faFilePdf} />
                      </a>
                    </button>
                  </div>
                }
              />
            </GlobalStyles.TableRow>
            {statements.map((s: any, id: number) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell input={s.date} />
                <TableCell
                  center
                  input={<OrderStatusBox style={{ width: 100, margin: 'auto' }} status={'$' + s.total} />}
                />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      <button>
                        <a
                          href={`/api/billing/statement/${s.id}/`}
                          rel="noreferrer"
                          target="__blank"
                          style={{ fontSize: '1.5em' }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} />
                        </a>
                      </button>
                    </div>
                  }
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
    </GenericModal>
  )
}
