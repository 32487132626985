import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { globalState } from '../../../store'

// Components
import { useFetch, usePut, notify } from '../../../components/component-items/helpers'
import GlobalStyles from '../../../components/component-items/styles'
import Pager, { Pages } from '../../../components/component-items/pager'
import { TableCell } from '../../../components/component-items/datatable'
import CardLoading from '../../../components/component-items/loading-popover'
import { OrderStatusBox } from '../../../components/component-items/status-box'
import { WebhookFormPage } from './WebhookFormPage'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faEdit, faEye } from '@fortawesome/pro-duotone-svg-icons'

const enum WebhookStatus {
  Disabled = 0,
  Enabled = 1,
  Locked = 2,
}

type WebhookRowProps = {
  data: any
  setShowWebhookModal: (show: boolean) => void
  setSelectedWebhoook: (data: any) => void
}
const WebhookRow = ({ data, setShowWebhookModal, setSelectedWebhoook }: WebhookRowProps) => {
  const {
    state: { csrf },
  } = globalState()
  const { event_type: eventType, error_rate: errorRate, name, url, status, id } = data
  const history = useHistory()

  const changeWebhookStatus = async (id: string, status: number) => {
    const response: any = await usePut(`/api/webhooks/${id}/`, { ...data, status }, csrf, false, true, true)
    if (response) {
      notify({ type: 'success', message: 'Successfully updated Webhook' })
    }
  }

  return (
    <GlobalStyles.TableRow>
      <TableCell center input={name} />
      <TableCell center input={url} />
      <TableCell
        center
        input={
          status === WebhookStatus.Locked ? (
            <GlobalStyles.Tooltip data-title="This endpoint is locked because all the status responses are not successful">
              <FontAwesomeIcon icon={faLock} />
            </GlobalStyles.Tooltip>
          ) : (
            <Form.Check
              type="switch"
              id={'active-' + id}
              defaultChecked={status === WebhookStatus.Enabled}
              onChange={() =>
                changeWebhookStatus(
                  id,
                  status === WebhookStatus.Enabled ? WebhookStatus.Disabled : WebhookStatus.Enabled
                )
              }
            />
          )
        }
      />
      <TableCell center style={{ textTransform: 'capitalize' }} input={eventType.replace('_', ' ')} />
      <TableCell
        input={
          <OrderStatusBox
            style={{ width: '60px', margin: 'auto', textAlign: 'center' }}
            status={`${errorRate} %`}
            status_id={errorRate < 20 ? '200' : errorRate < 50 ? '0' : '10'}
          />
        }
      />
      <TableCell
        center
        input={
          <>
            <button
              onClick={() => {
                setShowWebhookModal(true)
                setSelectedWebhoook(data)
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <GlobalStyles.Tooltip data-title="See event details of all webhook calls">
              <button
                onClick={() => {
                  history.push(`/company/webhooks/${id}/events`)
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            </GlobalStyles.Tooltip>
          </>
        }
      />
    </GlobalStyles.TableRow>
  )
}

export const ListWebhookPage = () => {
  const [page, setPage] = useState(1)
  const tableRowOptions = [100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const [showWebhookModal, setShowWebhookModal] = useState(false)
  const [selectedWebhoook, setSelectedWebhoook] = useState()
  const {
    state: { csrf },
  } = globalState()
  const {
    response: resp,
    loaded,
    error,
  }: any = useFetch(`/api/webhooks/?limit=${rows}&offset=${(page - 1) * rows}`, {})

  const webhooks = loaded ? resp.results : []
  const total = loaded ? resp.count : 0
  const totalPages = loaded && total ? Math.ceil(total / rows) : 1

  return (
    <>
      {loaded ? (
        <>
          <GlobalStyles.FullPageCard style={{ minHeight: 500 }}>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                <h3>
                  Webhooks List<small>{total} Total</small>
                </h3>
              </GlobalStyles.CardTitle>
              <GlobalStyles.CardToolbar>
                <GlobalStyles.Button className="primary" onClick={() => setShowWebhookModal(true)}>
                  Add Webhook
                </GlobalStyles.Button>
              </GlobalStyles.CardToolbar>
            </GlobalStyles.CardHeader>
            <GlobalStyles.DataTable>
              <thead>
                <tr>
                  <th>
                    <span className="text center">Name</span>
                  </th>
                  <th>
                    <span className="text center">Endpoint</span>
                  </th>
                  <th>
                    <span className="text center">Enabled</span>
                  </th>
                  <th>
                    <span className="text center">Event type</span>
                  </th>
                  <th>
                    <span className="text center">Error rate</span>
                  </th>
                  <th>
                    <span className="text center">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {webhooks.map((webhook: any) => (
                  <WebhookRow
                    data={webhook}
                    key={webhook.id}
                    setShowWebhookModal={setShowWebhookModal}
                    setSelectedWebhoook={setSelectedWebhoook}
                  />
                ))}
              </tbody>
            </GlobalStyles.DataTable>
            <GlobalStyles.CardFooter>
              <Pages page={page} setPage={setPage} total={totalPages} show={5} />
              <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
            </GlobalStyles.CardFooter>

            {showWebhookModal && (
              <WebhookFormPage
                hide={() => {
                  setShowWebhookModal(false)
                  setSelectedWebhoook(undefined)
                }}
                webhook={selectedWebhoook}
                onUpdate={() => {
                  window.location.reload()
                }}
              />
            )}
          </GlobalStyles.FullPageCard>
        </>
      ) : (
        <CardLoading error={error} />
      )}
    </>
  )
}
